/* eslint-disable @typescript-eslint/no-require-imports */
import '@findigs/ui-kit/src/css/global.legacy.scss';
import './src/utils/array-at-polyfill';
import type {
  WrapPageElementBrowserArgs,
  WrapRootElementBrowserArgs,
} from 'gatsby';
import Layout from './src/layout';
import Root from './src/root';

//FIXME: figure out why `import` is not working but `require` is
// import './src/css/app.scss';
require('./src/css/app.scss');

// Wraps every page in a component
export const wrapPageElement = ({ element }: WrapPageElementBrowserArgs) => {
  return <Layout>{element}</Layout>;
};

// Wraps the root element in a component
export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs) => {
  return <Root>{element}</Root>;
};
